import dayjs from 'dayjs';

export class GlobalConfig {
    static currency = {
        code: 'GBP',
        locale: 'en-GB',
    };
    static currencyUs = {
        code: 'USD',
        locale: 'en-US',
    };

    static format = {
        date: {
            // Simple formats

            // = 1969-12-31 23:59:59
            amDbDateTime: 'YYYY-MM-DD HH:mm:ss',

            // = 1969-12-31
            amDbDate: 'YYYY-MM-DD',

            // = 23:59:59
            amDbTime: 'HH:mm:ss',

            // Short formats

            // = Thu 12 Jul 2018 5:06 PM
            amShortDateTime: 'EE d MMM y h:mm a',

            // = 12 Jul, 20 15:06
            amShortDateTime2: 'd MMM, yy H:mm',


            // = 31/12/1969
            amShortDate: 'dd/MM/yyyy',

            amShortDate2: 'D MMM YY',

            amShortDay: 'ddd',

            amDay: 'EE',

            // = 11:59 PM
            amShortTime: 'H:mm A',

            // = 23:59
            amShortTime24: 'HH:mm',

            // Medium formats

            // = Wed 31 Dec 1969
            amMediumDate: 'ddd Do MMM YYYY',

            // = 31st Dec, 1969
            amMediumDate2: 'dd MMM, y',

            // = Wed 31 Dec 1969 11:59
            amMediumDateTime: '',

            // Full formats

            // = Wednesday, 31 December, 1969 11:59:59 PM
            amFullDateTime: 'dddd, Do MMMM, YYYY HH:mm:ss A',

            // = Wednesday, 31 December, 1969
            amFullDate: 'EEEE, dd MMMM, yyyy',

            // = Wednesday, 31 December
            amFullDateNoYear: 'dddd, Do MMMM',

            // = December 31st 1969
            amNaturalDate: 'MMMM Do YYYY',

            // = December 31 1969
            longDate: 'MMMM d, y',

            angular: {
                fullDate: 'EEE, d MMM y',
                fullDateTime: 'EEE, d MMM y h:mm a',
                dateOnly: 'd MMM, y',
                dateTime: 'd MMM, y h:mm a',
                timeOnly: 'h:mm a',
                month: 'MMM',
                year: 'y',
                monthYear: 'MMM, y',
                seconds: 'a',

            },
            dayjs: {
                fullDate: 'EEE, DD MMM YYYY',
                fullDateTime: 'EEE, DD MMM YYYY h:mm a',
                dateOnly: 'DD MMM, YYYY',
                dateTime: 'DD MMM, YYYY h:mm a',
                timeOnly: 'h:mm a',
            },
            dayjsCustom: {
                fullDate: 'D MMM, YYYY',
                // fullDate: 'ddd, Do MMM YYYY',
                fullDateTime: 'ddd, Do MMM YYYY HH:mm',
                dateOnly: 'Do MMM, YYYY',
                dateTime: 'Do MMM, YYYY HH:mm',
                dayTime: 'Do MMM, HH:mm',
                dayTime24: 'Do MMM, HH:mm',
                dateTime24: 'Do MMM, YYYY HH:mm',
                timeOnly: 'HH:mm',
                monthYear: 'MMM, YYYY',
                fullDayAndDateOnly: 'ddd, Do MMM YYYY',
            },
        },
        pDate: {
            amMediumDate2: 'dd M, yy',
            fullDate: 'EEE, d M yy',
            fullDateTime: 'EEE, d M yy h:mm a',
            dateOnly: 'dd M, yy',
            dateTime: 'd M, y h:mm a',
        }
    };
    static tableParams = {
        limits: [5, 10, 25, 50, 100, 500],
        pageNumber: 1,
        pageSize: {
            xs: 5,
            sm: 10,
            md: 25,
            lg: 50,
        },
    };

    static form = {
        defaultDropDownLabel: 'Please select',
        defaultDatePickerYearRange: '1970:2050',
        datePickerPastYearRange: '1970:' + dayjs().toDate().getFullYear(),
    };

    static modal = {
        size: {
            sm: '300px',
            md: '500px',
            lg: '700px',
            xl: '1000px',
        },
    };

}
