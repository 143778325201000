import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthService} from "@services/auth.service";
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  codec = new HttpUrlEncodingCodec;

  constructor(private authService: AuthService, private router: Router,) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.isAuthenticated.pipe(map(res => {
                if(!res){
                  this.codec.decodeValue(state.url);
                  this.router.navigate(['/auth/login'], {queryParams: {'intendedUrl': decodeURIComponent(state.url)}}).then();
                }
                return res
              }),
      );
  }
}
