<main class="main" fxLayout="column" fxFlex="auto" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</main>

<p-toast key="success">
	<ng-template let-message pTemplate="message">
		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
			<i class="fas fa-check-circle fa-2x success"></i>
			<div fxFlex="auto" fxLayout="column" fxLayoutAlign="start stretch">
				<div class="p-toast-summary">{{message.summary}}</div>
				<div class="p-toast-detail">{{message.detail}}</div>
			</div>
		</div>
	</ng-template>
</p-toast>

<p-toast key="error">
	<ng-template let-message pTemplate="message">
		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
			<i class="fas fa-times-circle fa-2x danger"></i>
			<div fxFlex="auto" fxLayout="column" fxLayoutAlign="start stretch">
				<div class="p-toast-summary">{{message.summary}}</div>
				<div class="p-toast-detail">{{message.detail}}</div>
			</div>
		</div>
	</ng-template>
</p-toast>
