<div fxLayout="column" fxLayoutAlign="center center" class="full-height">
  <div *ngIf="!loading" fxLayout="column" fxLayoutAlign="center center">
    <img [src]="'assets/images/http-error-page/' + httpErrorNumber + '.svg'" alt="" class="http-error-number img-responsive"/>
    <h1>{{heading}}</h1>
    <p class="text-center">
      {{description}}
    </p>
  </div>
  <button type="button" class="button button-s primary mt" (click)="redirect()">
    Go Home!
  </button>
</div>
