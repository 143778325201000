import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpErrorPageComponent} from './http-error-page/http-error-page.component';
import {FlexModule} from "@angular/flex-layout";


@NgModule({
  declarations: [
    HttpErrorPageComponent
  ],
  imports: [
    CommonModule,
    FlexModule
  ]
})
export class AppSharedModule {
}
