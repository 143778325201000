import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from "@environment";

@Injectable({
  providedIn: 'root'
})
export class BrowserTitleService {

  constructor(
    private titleService: Title,
  ) {
  }

  setTitle(title: string): void {
    // let domain: any = window.location.hostname.split('.');
    // if(environment.debug){
    //   if(domain[0]){
    //     domain = domain[0].toUpperCase() + ' - ';
    //   } else {
    //     domain = ' ';
    //   }
    // }

    const domain = ' ';

    const browserTitle = domain + title + ' | ' + environment.appName;
    this.titleService.setTitle(browserTitle);
  }

}
