import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from "@environment";

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    public http: HttpClient,
  ) {
  }

  post(url: any, params?: any, baseUrlInput = null): Observable<any> {
    // try {

    let baseUrl = '';
    const headers = this.getHeaders(null);
    const httpOptions = {
      headers,
    };
    if (baseUrlInput) {
      baseUrl = baseUrlInput;
    } else {
      baseUrl = this.baseUrl;
    }

    return this.http
      .post(baseUrl + url, params, httpOptions).pipe(
        switchMap((response: any) => {
          if (response.success) {
            return of(response);
          } else {
            //console.log(response);
            return throwError(response);
          }
        }), catchError(errorResponse => {
          // console.log(errorResponse);
          // if (errorResponse) {
          return throwError(errorResponse.errors);
          // }
        }));
    // } catch (e) {
    //   console.log(e);
    // }
  }

  get baseUrl() {
    return environment.baseUrlProtocol + environment.baseUrl;
  }

  get(url: any, params?: any, baseUrlInput = null): Observable<any> {
    let queryParams = this.toParam(params);
    let baseUrl = '';
    if (baseUrlInput) {
      baseUrl = baseUrlInput;
    } else {
      baseUrl = this.baseUrl;
    }
    queryParams = (queryParams) ? '?' + queryParams : '';
    const headers = this.getHeaders(null);
    const httpOptions = {
      headers,
    };
    return this.http
      .get(baseUrl + url + queryParams, httpOptions).pipe(
        switchMap((response: any) => {
          // const headers = this.getHeaders(response.token);
          if (response.success) {
            return of(response);
          } else {
            return throwError(response);
          }
        }), catchError(errorResponse => {
          console.log(errorResponse);
          return throwError(errorResponse.errors);
        }));
  }

  create(url: any, data: any) {
    return this.post(url, data);
  }

  read(url: any, id: any) {
    return this.get(url + '/' + id);
  }

  update(url: any, id: any, data: any) {
    return this.post(url + '/' + id, data);
  }

  getHeaders(token: any) {
    try {
      return new HttpHeaders();
    } catch (error) {
      console.log('Error in Request Service get Headers');
      console.log(error);
      return;
    }
  }

  public toParam(obj: any) {
    if (obj) {
      return Object.keys(obj).map(key => {
        const val = obj[key];
        const prefix = encodeURIComponent(key);
        return Array.isArray(val)
          ? _toPhpQueryArray(val, prefix)
          : _toPhpQueryObject(val, prefix);
      }).join('&');


      function _toPhpQueryArray(arr: any, prefix: any) {
        return arr.map((v: any) => prefix + '[]=' + encodeURIComponent(v)).join('&');
      }

      function _toPhpQueryObject(value: any, prefix: any) {
        if (typeof value === 'object' && value) {
          return Object.keys(value).map(k => _toPhpQueryObject(value[k], prefix + '[' + encodeURIComponent(k) + ']')).join('&');
        } else {
          return prefix + '=' + encodeURIComponent(value);
        }
      }
    } else {
      return null;
    }
  }

}
