import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {StorageMap} from '@ngx-pwa/local-storage';
import {catchError, first, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {User} from "@models/user";
import {RequestService} from "@services/request.service";
import dayjs from "dayjs";
import {UserService} from "@services/user.service";
import {ValidationService} from '@shared/components/form-errors/validation.service';
import {DialogService} from "primeng/dynamicdialog";
import {
    AutoLogoutAlertComponent
} from "../../modules/portal/portal-shared/auto-logout-alert/auto-logout-alert.component";
import {GlobalConfig} from "@shared/classes/GlobalConfig";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private user: User = null;
    public currentUserSubject = new BehaviorSubject<User>(null);
    public currentUser = new Observable<User>();
    private authenticated = false;
    private autoLogoutAlertOpen = false;

    constructor(
        protected request: RequestService,
        private storage: StorageMap,
        private router: Router,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
    ) {
        this.currentUser = this.currentUserSubject.asObservable();
        this.retrieveUserFromStorage$();
    }

    retrieveUserFromStorage$(): Observable<User> {
        return this.storage.get('currentUser').pipe(first(),
            map((user: User) => {
                return user;
            }),
        );
    }

    get isAuthenticated(): Observable<boolean> {
        // Are we authenticated already?
        // Here we'll check our local saved variable first, if it's false and we've landed on an authenticated route
        // then we'll want re-authenticate
        if (this.authenticated) {
            return of(true);
        } else {
            return this.request.post('api/auth/login').pipe( // we don't set credentials as we'll auto authenticate with token alone
                map(res => {
                    if (res.success) {
                        this.setUser(res.user);
                        this.setupAutoLogout(res.user.token.expires_at)
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']).then();
                        return false;
                    }
                }),
                catchError(() => {
                    // let params = this.route.snapshot.queryParams;
                    // if (params['intendedUrl']) {
                    //     this.intendedUrl = params['intendedUrl'];
                    // }
                    // if (this.intendedUrl) {
                    //     this.router.navigateByUrl('/' + this.intendedUrl).then();
                    // }
                    return of(false);
                }));
        }
    }

    authenticate(credentials) {
        return this.request.post('api/auth/login', credentials).pipe(map(res => {
            this.postLoginProcess(res.user);
            this.setupAutoLogout(res.user.token.expires_at)
            return {
                user: res.user,
            };
        }));
    }

    private getTimeDifference(sessionExpiresAt: any) {
        const currentTime = dayjs().tz('UTC');
        const expiresAt = dayjs.tz(sessionExpiresAt, 'UTC');
        const expiresInMins = (expiresAt.diff(currentTime, 'minutes'));
        // console.log('Current time: ' + currentTime.format('HH:mm'));
        // console.log('Expires in: ' + expiresInMins);
        // console.log('sessionExpiresAt', sessionExpiresAt);
        return expiresInMins;
    }

    public setupAutoLogout(sessionExpiresAt: any) {

        const expiresInMins = ( this.getTimeDifference(sessionExpiresAt) - 1 ) * 60 * 1000;
        setTimeout(() => {
            if (this.autoLogoutAlertOpen) {
                return;
            }
            this.getToken().subscribe(response => {
                const latestExpiresInMins = this.getTimeDifference(response.token.expires_at) - 1;
                if (latestExpiresInMins > 1) {
                    this.setupAutoLogout(response.token.expires_at);
                    return
                }
                this.showAutoLogoutAlert();
            });

        }, expiresInMins);
    }

    showAutoLogoutAlert() {
        this.autoLogoutAlertOpen = true;

        const ref = this.dialogService.open(AutoLogoutAlertComponent, {
            header: '',
            width: GlobalConfig.modal.size.md,
            closeOnEscape: false,
            closable: false,
            footer: '.',
            data: {
                dialogShown: true,
            },
        });

        ref.onClose.subscribe((response) => {
            this.autoLogoutAlertOpen = false;
            if (response !== 'keepLoggedIn') {
                this.logout();
            }
        });
    }

    postLoginProcess(user) {
        this.setUser(user);
    }

    public setUser(user: User): User {
        this.authenticated = true;
        this.user = user;
        this.currentUserSubject.next(user);
        this.storage.set('currentUser', user).subscribe();
        return user;
    }

    public updateUser(user: User): User {
        if (user) {
            Object.assign(this.user, user);
        } else {
            this.user = null;
        }
        this.currentUserSubject.next(user);
        this.storage.set('currentUser', user).subscribe();
        return user;
    }

    register(params) {
        return this.request.post('api/auth/register', params).pipe(map(res => {
            return this.setUser(res.user);
        }));
    }

    forgotPassword(params) {
        return this.request.post('api/auth/forgot-password', params);
    }

    validatePasswordToken(token) {
        return this.request.post('api/auth/validate-password-token', {token}).pipe(map(res => {
            return res.user as User;
        }));
    }

    extentTokenValidity() {
        return this.request.post('api/auth/token/extend-validity');
    }

    getToken() {
        return this.request.post('api/auth/token/get');
    }

    resetPassword(params: { password: string; token: string }) {
        return this.request.post('api/auth/reset-password', params);
    }

    validateAccessToken(key) {
        return this.request.post('api/validateActivateToken', {key}).pipe(map(res => {
            return res.user;
        }));
    }

    activateAccount(params) {
        return this.request.get('api/activateAccount', params).pipe(map(res => {
            return res;
        }));
    }

    generateOneTimeToken() {
        return this.request.get('api/auth/generate-one-time-token').pipe(map(response => response.token.value));
    }

    logout(): Subscription {
        this.storage.clear().subscribe(() => {
            this.authenticated = false;
            this.router.navigate(['/auth/login']).then();
        });
        return this.request.get('api/auth/logout').subscribe();
    }

    hasPermission(permission: string): boolean {
        let hasPermission = null;
        if (this.user) {
            hasPermission = this.user.permission_list.find((perm) => perm === permission);
        }
        return !!hasPermission;
    }

    getLoginForm() {
        return this.formBuilder.group({
            username: ['', Validators.compose([Validators.required])],
            password: ['', Validators.required],
            myRecaptcha: [false],
            client_type: ['web'],
            remember: [false],
        });
    }

    getRegistrationForm() {
        return this.formBuilder.group({
            first_name: ['', Validators.compose([Validators.required])],
            password: [null, Validators.compose([
                // 1. Password Field is Required
                Validators.required,
                // 2. check whether the entered password has a number
                ValidationService.passwordValidator(/\d/, {hasNumber: true}),
                // 3. check whether the entered password has upper case letter
                ValidationService.passwordValidator(/[A-Z]/, {hasCapitalCase: true}),
                // 4. check whether the entered password has a lower-case letter
                ValidationService.passwordValidator(/[a-z]/, {hasSmallCase: true}),
                // 5. check whether the entered password has a special character
                ValidationService.passwordValidator(/[$@$!%*?&]/, {hasSpecialCharacters: true}),
                // 6. Has a minimum length of 6 characters
                Validators.minLength(6)])
            ],
            contact_number: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, ValidationService.emailValidator])],
            company_name: ['', Validators.compose([Validators.required])],
            country: [null, Validators.compose([Validators.required])],
            myRecaptcha: [false, Validators.required],
        });
    }

    get twoFactorAuthenticated(): Observable<boolean> {
        return this.storage.get('currentUser').pipe(map((user: User) => {
            if (!user) {
                return false;
            }
            if (user.enable_tfa && dayjs(user.otp_expires_at).diff(dayjs(), 'days') <= 0) {
                this.router.navigateByUrl('/auth/2fa').then();
                return false;
            } else if (!user.enable_tfa && dayjs().diff(dayjs(user.created_at), 'days') > 30) {
                this.router.navigateByUrl('/auth/2fa').then();
                return false;
            }
            return true;
        }));
    }

    redirect(intendedUrl = null) {
        if (intendedUrl) {
            this.router.navigateByUrl('/' + intendedUrl).then();
        } else {
            if (this.hasPermission('admin-dashboard') || this.hasPermission('customer-dashboard')) {
                this.router.navigateByUrl('/dashboard').then();
            } else {
                this.router.navigateByUrl('/jobs/active').then();
            }
        }
    }

    getCountryOptions() {
        return [
            {
                label: 'UNITED KINGDOM',
                value: 'GB',
                code: 'UK'
            },
            {
                label: 'UNITED STATES',
                value: 'US',
                code: 'US',
            }
        ];
    }

}
