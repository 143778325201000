export enum Icons {
  'mini-cart' = '',
  'ready' = '',
  'customer-support' = '',
  'total_orders' = '',
  'order_progress' = '',
  'awaiting_orders' = '',
  'dashboard' = '',
  'orders' = '',
  'invoices' = '',
  'accounts' = '',
  'awaiting-quote' = '',
  'order-image' = '',
  'error-image-icon' ='',
  'order-complete-image' ='',
  'email-icon' = '',
  'password-lock' = '',
  'user' = '',
  'contact' = '',
  'company'= '',
  'country'= '',
  'es-logo'= '',
}
