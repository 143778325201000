import {Injectable} from '@angular/core';
import {RequestService} from "@services/request.service";

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  apiPrefix = '';

  constructor(
    protected request: RequestService,
  ) {
  }

  index(params = {}) {
    return this.request.get(this.apiPrefix + 'index', params);
  }

  create(params) {
    return this.request.post(this.apiPrefix + 'create', params);
  }

  createFolder(params) {
    return this.request.post(this.apiPrefix + 'create-folder', params);
  }

  update(id, params) {
    return this.request.post(this.apiPrefix + 'update/' + id, params);
  }

  save(params) {
    return this.request.post(this.apiPrefix + 'save', params);
  }

  read(id) {
    return this.request.get(this.apiPrefix + 'read/' + id);
  }

  readForCostJob(id) {
    return this.request.get(this.apiPrefix + 'read-cost-job/' + id);
  }

  delete(id) {
    return this.request.get(this.apiPrefix + 'delete/' + id);
  }

  options(params = {}) {
    return this.request.get(this.apiPrefix + 'options', params);
  }
}
