import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastModule} from "primeng/toast";
import {FlexModule} from "@angular/flex-layout";
import {IconService} from "@shared/services/icon.service";
import {CoreModule} from "./modules/core.module";
import {Angular2PromiseButtonModule} from "angular2-promise-buttons";
import {DatePipe} from "@angular/common";
import {AppSharedModule} from './modules/app-shared/app-shared.module';
import {DialogService} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {RecaptchaModule} from 'angular-google-recaptcha';
import {environment} from "@environment";
import {IntercomModule} from "ng-intercom";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        BrowserAnimationsModule,
        ToastModule,
        FlexModule,
        Angular2PromiseButtonModule.forRoot(),
        AppSharedModule,
        RecaptchaModule.forRoot({
            siteKey: environment.googleCaptchSiteKey,
        }),
        IntercomModule.forRoot({
            appId: 'fb2014wm', // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        })
    ],
    providers: [
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP'},
        CoreModule,
        DatePipe,
        DialogService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private iconService: IconService) {
        iconService.registerIcons();
    }
}
