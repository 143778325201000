<div fxLayout="column" fxLayoutGap="16px">
	<div class="smt" fxLayoutAlign="center center">
		<i class="fa fa-exclamation-circle fa-3x primary"></i>
	</div>
	<div class="text lg bold darker" fxLayoutAlign="center center">
		We haven't detected any activity in a while
	</div>
	<div class="text light-bold dark smt" fxLayoutAlign="center center">
		For security reasons, you'll be logged out in
	</div>
	<div class="text bold xxl darker smt" fxLayoutAlign="center center" id="countDownTimer"></div>
	<div class="smt" fxLayoutAlign="center center">
		<button type="button" class="button button-s primary" (click)="keepLoggedIn()">Keep me logged in</button>
	</div>
</div>
