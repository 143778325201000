import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {environment} from '@environment';
import {AuthService} from "@services/auth.service";

@Component({
    selector: 'app-auto-logout-alert',
    templateUrl: './auto-logout-alert.component.html',
    styleUrls: ['./auto-logout-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoLogoutAlertComponent implements OnInit {
    timeLeft = environment.debug ? 10 : 60;
    interval;

    constructor(
        public dialog: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        if (this.config.data.dialogShown) {
            this.startCountdown(this.timeLeft);
        }
    }

    keepLoggedIn() {
        clearInterval(this.interval);
        this.authService.extentTokenValidity().subscribe(response => {
            this.authService.setupAutoLogout(response.token.expires_at);
        });
        this.dialog.close('keepLoggedIn');
    }

    startCountdown(seconds) {
        let counter = seconds;

        this.interval = setInterval(() => {
            counter--;
            const countDownTimer = document.getElementById('countDownTimer') as HTMLElement;
            countDownTimer.innerHTML = counter + (counter > 1 ? ' seconds' : ' second');
            if (counter === 0) {
                clearInterval(this.interval);
                this.dialog.close();
            }
        }, 1000);
    }

}
