import {Injectable} from '@angular/core';
import {CrudService} from "@shared-services/crud.service";
import {RequestService} from "@services/request.service";
import {FormBuilder, Validators} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class UserService extends CrudService {
  apiPrefix = 'api/user/';

  constructor(
    protected request: RequestService,
    private formBuilder: FormBuilder,
  ) {
    super(request);
  }


  getNewForm() {
    return this.formBuilder.group({
      id: [],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.email],
      contact_number: [''],
      password: ['', Validators.required],
      roles: [null, Validators.required],
    })
  }

  saveSubUser(params) {
    return this.request.post(this.apiPrefix + 'save-sub-user', params);
  }

  saveFolder(params) {
    return this.request.post(this.apiPrefix + 'save-folder', params);
  }

  saveMobileNumber(params) {
    return this.request.post(this.apiPrefix + 'save-mobile-number', params);
  }

  saveTfaMethod(params) {
    return this.request.post(this.apiPrefix + 'save-tfa-method', params);
  }

  saveAccountProfile(params) {
    return this.request.post(this.apiPrefix + 'save-account', params);
  }

  allowAccess(params) {
    return this.request.post(this.apiPrefix + 'allow-access', params);
  }

  bulkAllowAccess(params) {
    return this.request.post(this.apiPrefix + 'bulk-allow-access', params);
  }

  removeAccess(params) {
    return this.request.post(this.apiPrefix + 'remove-access', params);
  }

  bulkRemoveAccess(params) {
    return this.request.post(this.apiPrefix + 'bulk-remove-access', params);
  }

  changePassword(params) {
    return this.request.post(this.apiPrefix + 'change-password', params);
  }

}
