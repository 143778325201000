import {Injectable, isDevMode} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {HttpError} from './HttpErrors';
import {MessageService} from 'primeng/api';
import {AuthService} from "@services/auth.service";
import {User} from "@models/user";
import {Router} from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const logFormat = 'background: red; color: white';
    // console.log(111);
  //   return next.handle(request)
  //     .pipe(
  //       // map(res => {
  //       //   console.log("Passed through the interceptor in response");
  //       //   return res
  //       // }),
  //       retry(0), // increase to try requests multiple times
  //       catchError((error: HttpErrorResponse) => {
  //         let errorMsg = '';
  //         // if (error.error instanceof ErrorEvent) {
  //         //   console.log('This is client side error');
  //         //   errorMsg = `Error: ${error.error.message}`;
  //         // } else {
  //         //   console.log('This is server side error');
  //         //   errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
  //         // }
  //         console.log(errorMsg);
  //         return throwError(errorMsg);
  //       })
  //     )
  // }


    return next.handle(request)
      .pipe(
        retry(0), // increase to try requests multiple times
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          if (isDevMode()) {
            console.log('%c ' + error.status + ' Exception', logFormat);
          }

          if (error.status === HttpError.Unauthorized) { // unauthorized
            // this.authService.logout();
            // throwError(error);
          } else if (error.status === HttpError.InternalServerError) {
            this.messageService.add({
              severity: 'error',
              key: 'error',
              summary: 'Internal server error',
              detail: 'This error has already been logged',
              // life: 999 * 99999,
            });
            console.log(error);
            return throwError(error.error);
          } else if (error.status === HttpError.BadRequest) {
            // console.log(error);
            return throwError(error.error);
          } else if (error.status === HttpError.NotFound) {
            console.log('api endpoint not found');
            this.messageService.add({
              severity: 'error',
              key: 'error',
              summary: '404 API not found',
              // life: 999 * 99999,
            });
          }else if (error.status === HttpError.Forbidden) {
            this.router.navigateByUrl('/403').then();
          }
          console.log(error);
          return throwError(error.error.message);
        })
      );
  }

}
