// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const domainName = 'edit-seven.com';

export const environment = {
    production: true,
    baseUrlProtocol: 'https://',
    baseUrl: 'api-prod.edit-seven.com/',
    appBaseDomain: 'app.edit-seven.com/',
    websiteBaseDomain: 'https://www.edit-seven.com/',
    appName: 'Edit Seven',
    pusherDebug: false,
    debug: false,
    localStorageVersion: '2',

    salesEmail: 'sales@edit-seven.com',
    helpEmail: 'help@edit-seven.com',

    googleCaptchSiteKey: '6LfbTOQUAAAAAHTTiKqIVXEsiO0T6G9NMXiEOgs0',

    boxEnabled: true,
    //Live
    boxRootFolderId: '187170285944',
    boxCustomerReferenceFolderId: '187170237777',

    extensionsAllowedForUpload: [
        'jpg',
        'jpeg',
        'psd',
        'bmp',
        'gif',
        'png',
        'tif',
        'tiff',
        'raw',
        'ai',
        'eps',
        'step',

        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'csv',

        'mp4',
        'mov',
        'wmv',
        'avi',
        'avchd',
        'flv',
        'f4v',
        'swf',
        'mkv',
        'webm',
        'html5',
        'mpeg-2',
        'mpeg',

        'zip',
    ],
    countableFileExtensions: [
        'jpg',
        'jpeg',
        'psd',
        'bmp',
        'gif',
        'png',
        'tif',
        'tiff',
        'raw',
        'ai',
        'step',
        'mp4',
        'mov',
        'wmv',
        'avi',
        'avchd',
        'flv',
        'f4v',
        'swf',
        'mkv',
        'webm',
        'html5',
        'mpeg',
        'mpeg-2',
    ],

    /*
      |--------------------------------------------------------------------------
      | Financial
      |--------------------------------------------------------------------------
    */
    vatString: '20%',
    vat: 0.2,

    /*
      |--------------------------------------------------------------------------
      | Contact Details
      |--------------------------------------------------------------------------
    */
    //'mailingAddress' => 'Ground Floor, Unit 2 Saddlers Court<br>Oakham, Leicester<br>LE15 7GH',
    mailingAddress: 'Unit 1C, Office 3 Uppingham Gate<br>Ayston Road, Uppingham<br>LE15 9NY',
    invoiceEmail: 'payments@edit-seven.com',
    //+44 (0) 1572 827 022
    tel: '+44 (0) 1572 503 021',
    vatNo: 'GB 164 0148 33',
    active_payment_gateway: 'stripe',

    // stripe_public_key: "pk_test_QJVjGAhHGIJopaF5FZXVmHNE",
    stripe_public_key: "pk_live_sLZ3YnGA3z1fSy7U3kcwYRSf",

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
