import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "@shared/guards/auth.guard";
import {TwoFactorAuthenticationGuard} from "@shared/guards/two-factor-authentication.guard";
import {HttpErrorPageComponent} from "./modules/app-shared/http-error-page/http-error-page.component";

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule),
        canActivate: [AuthGuard, TwoFactorAuthenticationGuard],
    },
    {
        path: '**',
        component: HttpErrorPageComponent,
        data: {
            title: '404 - Page Not Found',
            errorNumber: 404,
            heading: 'Page Not Found!',
            description: 'We’re sorry, the page you requested could not be found. Please go back to the homepage',
        },
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
