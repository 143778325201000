import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AuthService} from "@services/auth.service";
import {User} from "@models/user";
import {fromPromise} from "rxjs-compat/observable/fromPromise";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // We must make sure the user object is retrieved from local storage before we
    // try to amend the request to append the AuthToken header

    return fromPromise(this.authService.retrieveUserFromStorage$().toPromise()).pipe(
      mergeMap((user: User) => {
        if (user !== undefined) {
          const token = user.token ? user.token.value : '';
          req = req.clone({
            headers: req.headers.set('ApiToken', `${token}`),
          });
        }
        // Handle the request
        return next.handle(req);
      }));
  }
}
