import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, controlValue?: any) {
    controlValue = controlValue.charAt(0).toUpperCase() + controlValue.slice(1).replace(/_/g, ' ');
    const config = {
      required: `${controlValue} required`,
      invalidEmail: 'Invalid email address',
      email: 'Invalid email address',
      invalidPassword: 'Password must be at least 6 characters long, and contain a number.',
      passwordMismatch: 'Passwords don\'t match',
      postcode: 'Must be a valid UK postcode',
      // 'minlength': `Minimum length ${controlValue.requiredLength}`
    };
    return config[validatorName];
  }

  static emailValidator(control: AbstractControl) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
      return null;
    } else {
      return {invalidEmail: true};
    }
  }

  static passwordValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
  
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
  
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  
  static confirmPassword(control: FormControl, group: FormGroup, matchPassword: string) {
    if (group && group.controls[matchPassword].value === control.value) {
      return null;
    }
    return { passwordMismatch: true };
  }

  static postCodeValidator(control: FormControl)
  {
    const givenPostCode = control.value;
    const UKPostCodePattern = /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/;
    const isUKPostCodeValid = UKPostCodePattern.test(givenPostCode);
    if (!isUKPostCodeValid)
    {
      return { postcode: true };
    }
    return null;
  }
}
