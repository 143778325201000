import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BrowserTitleService} from '@shared-services/browser-title.service';
import {fadeAnimation} from "@shared/animations/fade.animation";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeAnimation]
})
export class AppComponent {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private browserTitleService: BrowserTitleService,
    ) {

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
            const rt = this.getChild(this.activatedRoute);
            rt.data.subscribe(data => {
                if (data && data.title) {
                    this.browserTitleService.setTitle(data.title);
                }
            });
        });
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    ngOnInit() {
    }
}
