import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from "@services/auth.service";
import {environment} from "@environment";

@Component({
  selector: 'app-http-error-page',
  templateUrl: './http-error-page.component.html',
  styleUrls: ['./http-error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpErrorPageComponent implements OnInit {
  loading = true;
  heading = '';
  description = '';
  httpErrorNumber: string;
  appName = environment.appName;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(params => {
      this.httpErrorNumber = params.errorNumber;
      this.heading = params.heading;
      this.description = params.description;
      this.loading = false;
      this.changeDetectorRef.markForCheck();
    });
  }

  redirect() {
    this.authService.redirect();
  }
}
