import {Injectable} from '@angular/core';
import {AuthService as Base} from "@shared/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Base {


  verifyOtp(params) {
    return this.request.post('api/auth/verify-otp', params);
  }

  generateOtp(params) {
    return this.request.post('api/auth/generate-otp', params);
  }



}
